import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-pearland-texas.png'
import image0 from "../../images/cities/scale-model-of-pearland-alliance-for-art-and-culture-in-pearland-texas.png"
import image1 from "../../images/cities/scale-model-of-air-sea-travel-&-tours-in-pearland-texas.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Pearland'
            state='Texas'
            image={image}
            lat='29.5635666'
            lon='-95.28604740000003'
            attractions={ [{"name": "Pearland Alliance for Art and Culture", "vicinity": "2341 N Galveston Ave #200, Pearland", "types": ["point_of_interest", "establishment"], "lat": 29.5652322, "lng": -95.28171020000002}, {"name": "Air-Sea Travel & Tours", "vicinity": "2520 E Broadway St, Pearland", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 29.5527344, "lng": -95.25573199999997}] }
            attractionImages={ {"Pearland Alliance for Art and Culture":image0,"Air-Sea Travel & Tours":image1,} }
       />);
  }
}